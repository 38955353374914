import { FC } from 'react';

import { Icon } from '@common/elements/Icon';

import ActionButtonIcon from './ActionButtonIcon';

import styles from './FeaturedCarrousel.module.scss';

export interface Props {
    isEditModeActive?: boolean;
    isFontIncreaseDisabled?: boolean;
    isFontDecreaseDisabled?: boolean;
    isSaveTitleDisabled?: boolean;
    onSave: () => void;
    onCancel: () => void;
    onEdit: () => void;
    onFontIncrease: () => void;
    onFontDecrease: () => void;
}

const ActionSection: FC<Props> = ({
    isEditModeActive,
    isFontIncreaseDisabled,
    isFontDecreaseDisabled,
    isSaveTitleDisabled,
    onCancel,
    onEdit,
    onSave,
    onFontDecrease,
    onFontIncrease,
}) => (
    <div className={styles['action-section-container']}>
        {isEditModeActive && (
            <>
                <ActionButtonIcon isDisabled={isFontIncreaseDisabled} onClick={onFontIncrease}>
                    <Icon.plusCircle />
                </ActionButtonIcon>

                <ActionButtonIcon isDisabled={isFontDecreaseDisabled} onClick={onFontDecrease}>
                    <Icon.minusCircle />
                </ActionButtonIcon>

                <hr className="vertical"></hr>

                <ActionButtonIcon isDisabled={isSaveTitleDisabled} onClick={onSave}>
                    <Icon.checkCircle />
                </ActionButtonIcon>

                <ActionButtonIcon onClick={onCancel}>
                    <Icon.close />
                </ActionButtonIcon>
            </>
        )}

        {!isEditModeActive && (
            <ActionButtonIcon onClick={onEdit}>
                <Icon.edit />
            </ActionButtonIcon>
        )}
    </div>
);

export default ActionSection;
