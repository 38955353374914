import { FC } from 'react';
import { useRouter } from 'next/router';

import { PureHtml } from '@common/atoms/PureHtml';
import { Ad } from '@common/clients/api';

interface Props {
    ad: Ad;
}

export const Placement: FC<Props> = ({ ad }) => {
    const { pathname } = useRouter();

    const dataItems: { [key: string]: string | true } = {};
    if (!ad.hasWrapper) dataItems['data-nosnippet'] = true;

    if (ad.content) {
        // FIXME: path might need to include host after removing php
        return <PureHtml html={ad.content.replace('{path}', `${pathname}`)} />;
    } else if (ad.targetingID) {
        return <div id={ad.targetingID} {...dataItems}></div>;
    } else {
        return <div id={ad.placement} {...dataItems}></div>;
    }
};
