import { FC } from 'react';

import { AdPlacement, PlatformID } from '@common/clients/api';
import { Ad } from '@web/molecules/Ad';
import { SidebarProps } from '@web/organisms/Sidebar';
import { Widget as MostLikedComments } from '@web/organisms/SideWidgets/MostLikedComments';
import { Widget as MostViewed } from '@web/organisms/SideWidgets/MostViewed';
import { NewsPopular } from '@web/organisms/SideWidgets/NewsPopular';
import { Widget as TransferRumour } from '@web/organisms/SideWidgets/TransferRumour';
import { Widget as UpcomingGP } from '@web/organisms/SideWidgets/UpcomingGrandPrix';

const switchFirstBlock = ({ platform, sidebarProps }: SidebarProps) => {
    switch (platform) {
        case PlatformID.VP:
        case PlatformID.VN:
        case PlatformID.BR:
            return sidebarProps?.['most-read'] || sidebarProps?.['most-commented'] ? (
                <>
                    <NewsPopular
                        isServerSideRendered
                        staticData={{
                            read: sidebarProps['most-read'] || [],
                            commented: sidebarProps['most-commented'] || [],
                        }}
                    />
                    <Ad placement={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_1} />
                </>
            ) : null;
        case PlatformID.GP:
            return sidebarProps?.['upcoming-gp'] ? (
                <>
                    <UpcomingGP {...sidebarProps['upcoming-gp']} hasVerticalSchedule />
                    <Ad placement={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_1} />
                </>
            ) : null;
        default:
            return null;
    }
};

const switchSecondBlock = ({ platform, sidebarProps }: SidebarProps) => {
    switch (platform) {
        case PlatformID.VP:
        case PlatformID.VN:
            return sidebarProps?.['transfer-rumour'] ? (
                <>
                    <TransferRumour dataList={sidebarProps['transfer-rumour']} />
                    <Ad placement={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_2} />
                </>
            ) : null;
        case PlatformID.BR:
            return sidebarProps?.['most-viewed'] ? (
                <>
                    <MostViewed dataList={sidebarProps['most-viewed']} />
                    <Ad placement={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_2} />
                </>
            ) : null;
        case PlatformID.GP:
            return sidebarProps?.['most-read'] || sidebarProps?.['most-commented'] ? (
                <>
                    <NewsPopular
                        isServerSideRendered
                        staticData={{
                            read: sidebarProps['most-read'] || [],
                            commented: sidebarProps['most-commented'] || [],
                        }}
                    />
                    {!sidebarProps['upcoming-gp'] ? (
                        <Ad placement={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_1} />
                    ) : null}
                    {sidebarProps['upcoming-gp'] ? (
                        <Ad placement={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_2} />
                    ) : null}
                </>
            ) : null;
        default:
            return null;
    }
};

const switchThirdBlock = ({ platform, sidebarProps }: SidebarProps) => {
    switch (platform) {
        case PlatformID.VP:
        case PlatformID.VN:
            return sidebarProps?.['most-liked-comments'] ? (
                <>
                    <MostLikedComments dataList={sidebarProps['most-liked-comments']} />
                    {!sidebarProps?.['transfer-rumour'] ? (
                        <Ad placement={AdPlacement.HOME_SIDEBAR_AFTER_SECTION_2} />
                    ) : null}
                </>
            ) : null;
        default:
            return null;
    }
};

export const RightColHomepage: FC<SidebarProps> = ({ platform, sidebarProps }: SidebarProps) => {
    return (
        <>
            <Ad placement={AdPlacement.HOME_SIDEBAR_TOP} />
            {switchFirstBlock({ platform, sidebarProps })}
            {switchSecondBlock({ platform, sidebarProps })}
            {switchThirdBlock({ platform, sidebarProps })}
        </>
    );
};
