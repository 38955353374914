import { FC } from 'react';

import { AdPlacement, PlatformID } from '@common/clients/api';
import { Ad } from '@web/molecules/Ad';
import { SidebarProps } from '@web/organisms/Sidebar';
import { Widget as TransferRumour } from '@web/organisms/SideWidgets/TransferRumour';

/**
 * FIXME: PB-4625 - Continue converting the remaining right_col templates to react/tsx
 * for the server side rendered sidebear
 */
export const RightColMedia: FC<SidebarProps> = ({ platform, sidebarProps = {} }) => {
    if (platform !== PlatformID.GP) {
        return (
            <>
                <Ad placement={AdPlacement.NON_HOME_SIDEBAR_TOP} />
                {/* FIXME: PB-4625 - Replace placeholder title with widget */}
                <h5>Most watched widget (Non GP)</h5>
                <Ad placement={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_1} />
                {sidebarProps['transfer-rumour'] ? (
                    <>
                        <TransferRumour dataList={sidebarProps['transfer-rumour']} />
                        <Ad placement={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_2} />
                    </>
                ) : null}
            </>
        );
    } else {
        return (
            <>
                <Ad placement={AdPlacement.NON_HOME_SIDEBAR_TOP} />
                {/* FIXME: PB-4625 - Replace placeholder title with widget */}
                <h5>Most watched widget (GP)</h5>
                <Ad placement={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_1} />
            </>
        );
    }
};
