import { FC } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { FeaturedItem, GrandPrix } from '@common/clients/api';
import { ContextData } from '@common/defaults';
import { useContextData } from '@common/useContextData';
import { ReactPortal } from '@web/atoms/ReactPortal';
import { ResponsiveToggle } from '@web/atoms/ResponsiveToggle';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import { FeaturedCarrousel } from '@web/molecules/FeaturedCarrousel';
import { GrandPrixWidget } from '@web/molecules/GrandPrixWidget/GrandPrixWidget';
import { MatchTicker } from '@web/molecules/MatchTicker';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { BreadCrumbList, WebPageStructuredData } from '@web/molecules/StructuredData';
import { Sidebar, SidebarProps } from '@web/organisms/Sidebar';
import { WidgetType } from '@web/organisms/Sidebar/types';
import { VideoCarousel } from '@web/organisms/VideoCarousel';
import { Route } from '@web/routing';

export interface State {
    items: FeaturedItem[];
    upcomingGP?: GrandPrix;
}

export interface Props extends ContextData, SidebarProps {
    state: State;
}

export const HomePage: FC<Props> = ({ context, state, ...props }) => {
    const {
        context: {
            domain: { platform },
        },
    } = useContextData();
    const { sidebarProps } = props;

    // TODO: set this value correctly after having the update news article implemented [PB-3973]
    const isEditable = false;

    const __meta = useTranslation('meta').t;
    const metaTitle = __meta(`${context.platform.id}-homepage.title`);
    return (
        <>
            <WebPageStructuredData
                title={metaTitle}
                description={__meta(`${context.platform.id}-homepage.description`)}
            />
            <BreadCrumbList route={Route.Homepage} title={metaTitle} />
            <Ad placement={AdPlacement.HOME_BEFORE_CONTENT} />
            <FeaturedCarrousel items={state.items} isEditable={isEditable} />
            <Ad placement={AdPlacement.HOME_AFTER_CAROUSEL} />
            {sidebarProps && sidebarProps[WidgetType.UPCOMING_GP] ? (
                <ResponsiveToggle
                    mobile={
                        <GrandPrixWidget
                            {...sidebarProps[WidgetType.UPCOMING_GP]}
                            tag={__meta`f1-calendar.upcoming-race`}
                        />
                    }
                />
            ) : null}
            <ReactPortal containerID={'_next_video_carousel_portal'}>
                <VideoCarousel />
            </ReactPortal>
            <MatchTicker />
            <Sidebar {...{ platform, sidebarProps }} />
            <NotificationBarHolder />
        </>
    );
};
