import { FC } from 'react';

import { SidebarProps } from '@web/organisms/Sidebar';
import { RightCol } from '@web/organisms/Sidebar/molecules/RightCol';
import { RightColHomepage } from '@web/organisms/Sidebar/molecules/RightColHomepage';
import { RightColMedia } from '@web/organisms/Sidebar/molecules/RightColMedia';
import { RightColMediaIndex } from '@web/organisms/Sidebar/molecules/RightColMediaIndex';
import { RightColMisc } from '@web/organisms/Sidebar/molecules/RightColMisc';

/**
 * Enum to define the sidebar widget/ad sequence
 * @example
 * SidebarSequence.RIGHT_COL
 * SidebarSequence.RIGHT_COL_HOMEPAGE
 * SidebarSequence.RIGHT_COL_MEDIA
 * SidebarSequence.RIGHT_COL_MEDIA_INDEX
 * SidebarSequence.RIGHT_COL_MISC
 */
export enum SidebarSequence {
    RIGHT_COL = 'right-col',
    RIGHT_COL_HOMEPAGE = 'right-col-homepage',
    RIGHT_COL_MEDIA = 'right-col-media',
    RIGHT_COL_MEDIA_INDEX = 'right-col-media-index',
    RIGHT_COL_MISC = 'right-col-misc',
}

export const Sequence: FC<SidebarProps> = ({ platform, sidebarProps }) => {
    switch (sidebarProps?.sequence) {
        case SidebarSequence.RIGHT_COL:
            return <RightCol {...{ platform, sidebarProps }} />;
        case SidebarSequence.RIGHT_COL_HOMEPAGE:
            return <RightColHomepage {...{ platform, sidebarProps }} />;
        case SidebarSequence.RIGHT_COL_MEDIA:
            return <RightColMedia {...{ platform, sidebarProps }} />;
        case SidebarSequence.RIGHT_COL_MEDIA_INDEX:
            return <RightColMediaIndex {...{ platform, sidebarProps }} />;
        case SidebarSequence.RIGHT_COL_MISC:
            return <RightColMisc {...{ platform, sidebarProps }} />;
        default:
            return null;
    }
};
