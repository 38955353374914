import { FC } from 'react';

import { PlatformID } from '@common/clients/api';
import {
    PlatformIncludeWidgets,
    SidebarData,
    SidebarSequenceWidgets,
    WidgetType,
} from '@web/organisms/Sidebar/types';
import { Sequence, SidebarSequence } from '@web/organisms/Sidebar/utils/Sequence';

import styles from './Sidebar.module.scss';

/**
 * Configure which widgets are included for each platform using enum values
 */
export const PLATFORM_INCLUDE_WIDGETS: PlatformIncludeWidgets = {
    [PlatformID.BR]: [WidgetType.MOST_READ, WidgetType.MOST_VIEWED],
    [PlatformID.GP]: [
        WidgetType.MOST_READ,
        WidgetType.MOST_COMMENTED,
        WidgetType.MOST_VIEWED,
        WidgetType.UPCOMING_GP,
    ],
    [PlatformID.VI]: [],
    [PlatformID.VN]: [
        WidgetType.MOST_LIKED_COMMENTS,
        WidgetType.MOST_READ,
        WidgetType.MOST_COMMENTED,
        WidgetType.TRANSFER_RUMOUR,
    ],
    [PlatformID.VP]: [
        WidgetType.MOST_LIKED_COMMENTS,
        WidgetType.MOST_READ,
        WidgetType.MOST_COMMENTED,
        WidgetType.TRANSFER_RUMOUR,
    ],
};

/**
 * Configure which widgets are included for each sequence using enum values
 */
export const SEQUENCE_INCLUDE_WIDGETS: SidebarSequenceWidgets = {
    [SidebarSequence.RIGHT_COL]: [],
    [SidebarSequence.RIGHT_COL_HOMEPAGE]: [
        WidgetType.MOST_LIKED_COMMENTS,
        WidgetType.MOST_READ,
        WidgetType.MOST_COMMENTED,
        WidgetType.TRANSFER_RUMOUR,
        WidgetType.MOST_VIEWED,
        WidgetType.UPCOMING_GP,
    ],
    [SidebarSequence.RIGHT_COL_MEDIA]: [],
    [SidebarSequence.RIGHT_COL_MEDIA_INDEX]: [],
    [SidebarSequence.RIGHT_COL_MISC]: [],
};

export interface Props {
    platform?: PlatformID;
    sidebarProps?: SidebarData;
}

/**
 * Server side rendered sidebar
 * @example
 * <Sidebar platform={PlatformID.BR} sidebarProps={props.sidebarProps}/>
 */
export const Sidebar: FC<Props> = ({ platform, sidebarProps }) => {
    return (
        <aside className={`${styles.Sidebar} sidebar no-match-ticker`}>
            <Sequence {...{ platform, sidebarProps }} />
        </aside>
    );
};
