import { FC, useEffect, useState } from 'react';

import { HtmlComment } from '@common/atoms/HtmlComment';
import { AdsByPlacement } from '@common/types';
import { useContextData } from '@common/useContextData';

import { CombinedWrapper, OptionalAd, Placement, Wrapper } from './atoms';
import { AdPlacement } from './';

export interface Props {
    placement: AdPlacement;
    isOptional?: boolean;
}

declare global {
    interface Window {
        ndmOne: any;
        ndmOneStub: any;
    }
}

export const Ad: FC<Props> = ({ placement, isOptional }) => {
    const { ads } = useContextData();

    // In most cases the ads are within the contextData, but when they're not we'll get a promise from _app.tsx that we should wait for
    const [loadedAds, setLoadedAds] = useState<AdsByPlacement | null>(
        ads && !(ads instanceof Promise) ? ads : null,
    );

    useEffect(() => {
        const waitForAds = async () => {
            const defAds = await ads;
            if (defAds) setLoadedAds(defAds);
        };
        if (ads instanceof Promise) waitForAds();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (isOptional) return <OptionalAd placement={placement}></OptionalAd>;

    // Render nothing if there is no loadedAds
    if (!loadedAds) return null;

    if (!loadedAds?.[placement]?.length) {
        return <HtmlComment text={`no ${placement} ad`} />;
    }

    const placementAds = loadedAds[placement];

    // Combine wrapper if content, targetingID and hasWrapper are the same
    if (
        placementAds.length === 2 &&
        placementAds[0].hasWrapper &&
        placementAds[1].hasWrapper &&
        placementAds[0].targetingID === placementAds[1].targetingID &&
        placementAds[0].content === placementAds[1].content
    ) {
        return (
            <>
                <HtmlComment text={`${placement} ad`} />
                <CombinedWrapper ads={[placementAds[0], placementAds[1]]} />
            </>
        );
    }

    return (
        <>
            <HtmlComment text={`${placement} ad`} />
            {placementAds.map((ad, index) => {
                return ad.hasWrapper ? (
                    <Wrapper key={`ad-${index}`} ad={ad} />
                ) : (
                    <Placement key={`ad-${index}`} ad={ad} />
                );
            })}
        </>
    );
};
