import { FC } from 'react';

import { AdPlacement, PlatformID } from '@common/clients/api';
import { Ad } from '@web/molecules/Ad';
import { SidebarProps } from '@web/organisms/Sidebar';
import { RightCol } from '@web/organisms/Sidebar/molecules/RightCol';

/**
 * FIXME: PB-4625 - Continue converting the remaining right_col templates to react/tsx for the server side rendered sidebear
 */
export const RightColMisc: FC<SidebarProps> = ({ platform, sidebarProps }) =>
    platform === PlatformID.GP ? (
        <RightCol platform={platform} sidebarProps={sidebarProps} />
    ) : (
        <>
            <Ad placement={AdPlacement.NON_HOME_SIDEBAR_TOP} />
            {/* FIXME: PB-4625 - Replace placeholder title with widget */}
            <h5>Popular Widget (Non GP)</h5>
            <Ad placement={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_1} />
        </>
    );
