import { FC, memo, useCallback, useEffect, useState } from 'react';

import { VideoCarousel as VideoCarouselBase } from './ClientSideVideoCarousel';
import { Props } from './VideoCarousel';

export const Component: FC<Props> = (props) => {
    const [hasScrolled, setHasScrolled] = useState<boolean>(false);

    const handleScroll = useCallback(() => {
        setHasScrolled(true);
        window.removeEventListener('scroll', handleScroll);
    }, [setHasScrolled]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return hasScrolled ? <VideoCarouselBase {...props} /> : null;
};

export const VideoCarousel = memo(Component);
