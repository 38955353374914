import { FC } from 'react';

import { PlatformID } from '@common/clients/api';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import { SidebarProps } from '@web/organisms/Sidebar';

/**
 * FIXME: PB-4625 - Continue converting the remaining right_col templates to react/tsx for the server side rendered sidebear
 */
export const RightColMediaIndex: FC<SidebarProps> = ({ platform }) => (
    <>
        <Ad placement={AdPlacement.NON_HOME_SIDEBAR_TOP} />
        {platform === PlatformID.GP ? (
            <>
                {/* FIXME: PB-4625 - Replace placeholder title with widget */}
                <h5>Most watched widget (GP)</h5>
                <Ad placement={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_1} />
                <h5>Popular widget (GP)</h5>
                <Ad placement={AdPlacement.NON_HOME_SIDEBAR_AFTER_SECTION_2} />
            </>
        ) : null}
    </>
);
