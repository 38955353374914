import { FC } from 'react';

import { PlatformID } from '@common/clients/api';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import { SidebarProps } from '@web/organisms/Sidebar';
import { Widget as TransferRumour } from '@web/organisms/SideWidgets/TransferRumour';

/**
 * FIXME: PB-4625 - Continue converting the remaining right_col templates to react/tsx for the server side rendered sidebear
 */
export const RightCol: FC<SidebarProps> = ({ platform, sidebarProps = {} }) => {
    return (
        <>
            <Ad placement={AdPlacement.NON_HOME_SIDEBAR_TOP} />
            {/* FIXME: PB-4625 - Replace placeholder title with widget */}
            {platform === PlatformID.GP ? <h5>Popular Widget (Only GP)</h5> : null}
            {platform === PlatformID.VP || platform === PlatformID.VN ? (
                sidebarProps?.['transfer-rumour'] ? (
                    <TransferRumour dataList={sidebarProps['transfer-rumour']} />
                ) : null
            ) : null}
            {/* FIXME: PB-4625 - Replace placeholder title with widget */}
            {platform !== PlatformID.GP ? <h5>Popular Widget (Non GP)</h5> : null}
            {platform === PlatformID.BR ? <h5>Most Watched Widget (BR)</h5> : null}
            {platform !== PlatformID.BR && platform !== PlatformID.GP ? (
                <h5>Popular Comments (Non BR)</h5>
            ) : null}
        </>
    );
};
