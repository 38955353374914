import { FC } from 'react';
import { head } from 'lodash';
import { useRouter } from 'next/router';
import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';
import type { BreadcrumbList, ListItem, WithContext } from 'schema-dts';

import { useContextData } from '@common/useContextData';
import { formatRoute, Route } from '@web/routing';

import { StructuredData } from './atoms/StructuredData';

export interface Props {
    route: Route;
    title?: string;
    parents?: Route[];
}

const getDefaultTitleByRoute = (platform: string, route: Route, __meta: Translate): string => {
    switch (route) {
        case Route.BrandOverview:
        case Route.CarOverview:
        case Route.CircuitOverview:
        case Route.ClubOverview:
        case Route.CompetitionOverview:
        case Route.DossierOverview:
        case Route.DriverOverview:
        case Route.F1Calendar:
        case Route.GrandsprixOverview:
        case Route.LocationOverview:
        case Route.OrganizationOverview:
        case Route.PersonOverview:
        case Route.PlayerOverview:
        case Route.RaceClassOverview:
        case Route.TeamBossOverview:
        case Route.TeamOverview:
        case Route.TopicOverview:
        case Route.MatchOverview:
        case Route.VideoOverview:
            return __meta(`${route}.title`);
        case Route.Homepage:
            return platform;
        case Route.User:
            return __meta`users.title`;
        default:
            console.warn('BreadCrumbList: The provided route has no default name');
            return '';
    }
};

export const BreadCrumbList: FC<Props> = ({ route, title, parents = [] }) => {
    const { context, routePrefix } = useContextData();
    const { domain, platform } = context;
    const router = useRouter();
    const __meta = useTranslation('meta').t;

    const prepareItem = (_route: Route, index: number, name?: string): ListItem => {
        const route = _route as Route;
        return {
            '@type': 'ListItem',
            position: index + 1,
            name: name ? name : getDefaultTitleByRoute(platform.name, route, __meta),
            item: formatRoute(domain, route, routePrefix, __meta, router.query),
        };
    };

    if ([route, head(parents)].indexOf(Route.Homepage) < 0) parents.unshift(Route.Homepage);
    const jsonListItems: ListItem[] = parents?.map((_route, index) => prepareItem(_route, index)) || [];
    jsonListItems.push(prepareItem(route, jsonListItems.length, title));

    const data: WithContext<BreadcrumbList> = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: jsonListItems,
    };

    return <StructuredData data={data} entity={'BreadcrumbList'} />;
};
