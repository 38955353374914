
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { FeaturedCarouselService, FeaturedItem } from '@common/clients/api';
import { ApiBaseRequest } from '@common/clients/request';
import { defaultCatch } from '@common/defaults';
import { fetchSidebarData } from '@web/organisms/Sidebar/utils';
import { SidebarSequence } from '@web/organisms/Sidebar/utils/Sequence';
import { HomePage, Props, State } from '@web/templates/HomePage';
import { populateContextData, propsPromises } from '@web/utils/populateContextData';
const fetchFeaturedItems = async (props: Props, isClientSide: boolean): Promise<FeaturedItem[]> => {
    const request = new ApiBaseRequest(props, isClientSide);
    const featuredCarouselService = new FeaturedCarouselService(request);
    const response = await featuredCarouselService
        .getFeaturedCarouselByDomainId({
        contextId: props.context.domain.domainID
    })
        .catch(defaultCatch);
    return response?.items || [];
};
const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
    const state: State = {
        items: []
    };
    const props: Props = {
        ...populateContextData(context),
        state: state
    };
    const [items, sidebarProps] = await Promise.all([
        fetchFeaturedItems(props, true),
        fetchSidebarData(props, SidebarSequence.RIGHT_COL_HOMEPAGE),
    ]);
    if (items)
        state.items = items;
    if (sidebarProps) {
        props.sidebarProps = sidebarProps;
    }
    return {
        props: await propsPromises(props)
    };
};
export const Home = HomePage;
export default Home;

    async function __Next_Translate__getServerSideProps__18c7a87c4ee__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__18c7a87c4ee__ as getServerSideProps }
  