import { FC, LegacyRef } from 'react';
import { script } from '@common/regexp/script';

interface Props {
    html: string;
}

export const PureHtml: FC<Props> = ({ html }) => {
    const callback: LegacyRef<HTMLElement> = (instance: HTMLElement): void => {
        if (instance && instance.parentNode) {
            instance.outerHTML = html;
        }
    };

    return (
        <>
            {html.match(script) ? (
                <div ref={callback} dangerouslySetInnerHTML={{ __html: html }} />
            ) : (
                <script ref={callback} dangerouslySetInnerHTML={{ __html: html }} />
            )}
        </>
    );
};
